import React, { useState, useEffect } from "react";
import styled from "styled-components";
import galaxyMin from "../assets/galaxy-min.jpg";
import galaxy from "../assets/galaxy.jpg";
import AnalogueClock from "./AnalogueClock";

const Wrapper = styled.div`
  background-image: ${(p) => `url(${p.src})`};
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Time = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const Center = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #000000aa;
  padding: 1rem 4rem;
  cursor: pointer;
  outline-color: transparent;
`;
const Text = styled.h1`
  color: #111;
  font-family: monospace;
  font-size: calc(2px + 12vmin);
  white-space: nowrap;
`;
const Seconds = styled.span`
  color: #ffffffdd;
`;

const formatTime = (time) => {
  if (time < 10) return `0${time}`;
  return time;
};

const Clock = () => {
  const [now, setNow] = useState(new Date());
  const [count, setCount] = useState(0);
  const [digitalClock, setDigitalClock] = useState(false);
  const [image, setImage] = useState(galaxyMin);

  useEffect(() => {
    const img = new Image();
    img.src = galaxy;
    img.onload = () => {
      setImage(galaxy);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setCount(count + 1);
      setNow(new Date());
    }, 1000);
  }, [count]);
  const hh = formatTime(now.getHours());
  const mm = formatTime(now.getMinutes());
  const ss = formatTime(now.getSeconds());
  return (
    <Wrapper src={image || galaxyMin}>
      <Time>
        <Center onClick={() => setDigitalClock(!digitalClock)}>
          {digitalClock ? (
            <Text>
              <Seconds>
                {hh}:{mm}:{ss}
              </Seconds>
            </Text>
          ) : (
            <AnalogueClock
              hours={now.getHours()}
              minutes={now.getMinutes()}
              seconds={now.getSeconds()}
            />
          )}
        </Center>
      </Time>
    </Wrapper>
  );
};

export default Clock;
