import React from "react";
import styled from "styled-components";

const Wrapper = styled.svg`
  stroke-width: 1;
  stroke-linecap: round;
  fill: transparent;
  stroke: #ffffff;
  stroke-width: 2;
  transform: rotate(-90deg);
  margin: 2rem;
`;

const Marks = styled.g`
  transform: translate(50px, 50px);
  stroke-width: 1;
  line {
    :nth-child(1) {
      transform: rotate(30deg);
    }
    :nth-child(2) {
      transform: rotate(60deg);
    }
    :nth-child(3) {
      transform: rotate(90deg);
      stroke-width: 2;
    }
    :nth-child(4) {
      transform: rotate(120deg);
    }
    :nth-child(5) {
      transform: rotate(150deg);
    }
    :nth-child(6) {
      transform: rotate(180deg);
      stroke-width: 2;
    }
    :nth-child(7) {
      transform: rotate(210deg);
    }
    :nth-child(8) {
      transform: rotate(240deg);
    }
    :nth-child(9) {
      transform: rotate(270deg);
      stroke-width: 2;
    }
    :nth-child(10) {
      transform: rotate(300deg);
    }
    :nth-child(11) {
      transform: rotate(330deg);
    }
    :nth-child(12) {
      transform: rotate(360deg);
      stroke-width: 2;
    }
  }
`;

const Hour = styled.line`
  transform: ${(p) => `translate(50px, 50px) rotate(${p.hours * 30}deg)`};
  stroke-width: 2;
`;
const Minute = styled.line`
  transform: ${(p) => `translate(50px, 50px) rotate(${p.minutes * 6}deg)`};
  stroke-width: 1.2;
`;
const Second = styled.line`
  transform: ${(p) => `translate(50px, 50px) rotate(${p.seconds * 6}deg)`};
  stroke-width: 0.5;
  stroke: #d0050555;
`;
const Pin = styled.circle`
  stroke-width: 0.5;
  fill: white;
`;

const AnalogueClock = ({ seconds, minutes, hours }) => {
  const size = document.documentElement.clientWidth * 0.4;
  const x1 = 50;
  const x2 = 45;
  return (
    <Wrapper viewBox="0 0 100 100" width={size} height={size}>
      <Marks>
        <line x1={x1} y1="0" x2={x2} y2="0" />
        <line x1={x1} y1="0" x2={x2} y2="0" />
        <line x1={x1} y1="0" x2={x2} y2="0" />
        <line x1={x1} y1="0" x2={x2} y2="0" />
        <line x1={x1} y1="0" x2={x2} y2="0" />
        <line x1={x1} y1="0" x2={x2} y2="0" />
        <line x1={x1} y1="0" x2={x2} y2="0" />
        <line x1={x1} y1="0" x2={x2} y2="0" />
        <line x1={x1} y1="0" x2={x2} y2="0" />
        <line x1={x1} y1="0" x2={x2} y2="0" />
        <line x1={x1} y1="0" x2={x2} y2="0" />
        <line x1={x1} y1="0" x2={x2} y2="0" />
      </Marks>
      <Hour x1="0" y1="0" x2="22" y2="0" hours={hours} />
      <Minute x1="0" y1="0" x2="32" y2="0" minutes={minutes} />
      <Second x1="0" y1="0" x2="40" y2="0" seconds={seconds} />
      <Pin cx="50" cy="50" r="1.7" />
    </Wrapper>
  );
};

export default AnalogueClock;
